import Image from 'next/image';

import { getUrlExtension, isPNG } from '@/lib/utils';
import FallBackIMG from '@/assets/images/fallback-product-card.jpg';

import type { FunctionComponent } from 'react';
import type { TypeImageProps } from './types';

export const ImageCustom: FunctionComponent<TypeImageProps> = ({
  src,
  alt,
  size = 'medium',
}: TypeImageProps): JSX.Element => {
  const srcWithFallback = src && src !== '' ? src : FallBackIMG.src;
  const altWithFallback = alt && alt !== '' ? alt : 'fallback image';
  const objectFit = isPNG(getUrlExtension(srcWithFallback))
    ? 'object-contain'
    : 'object-cover';

  return (
    <Image
      src={srcWithFallback}
      alt={altWithFallback}
      className={`object-center ${objectFit}`}
      draggable="false"
      fill
      {...(size === 'small'
        ? {
            sizes:
              '(max-width: 360px) 100vw, (max-width: 640px) 50vw, (max-width: 768px) 33vw, (max-width: 1024px) 25vw, (max-width: 1280px) 20vw, 16vw',
          }
        : {
            sizes:
              '(max-width: 360px) 100vw, (max-width: 640px) 50vw, (max-width: 768px) 33vw, 25vw',
          })}
    />
  );
};
